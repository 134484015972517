import React, { useEffect, useState , useRef} from 'react'
import { Link } from "react-router-dom"
import './Content.css'
import {
  Typography,
  Card,
  CardContent,
  Box,
  CardMedia,
  Chip,
  Button,
  CircularProgress
} from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"
import Skeleton from "@material-ui/lab/Skeleton"
import moment from "moment"
import { makeAuthorString, getVideoEmbedUrl } from "../../helpers"
import addPdfWatermark from "../../helpers/addPdfWatermark"
// Import utilities
import parse from 'html-react-parser'

const ArticleCard = ({ cardData, category, classes, user, openSnackbar }) => {
  const [content, setContent] = useState([])
  const [processingDownload, setprocessingDownload] = useState(false)

  // Tracks clicks on the download button and sends it to Segment.io
  async function handleDownloadButtonClick(data) {
    setprocessingDownload(true)

    window.analytics.track('Clicked Download', {
      id: data.id,
      title: data.title,
      type: data.subcategory.category.name,
      category: data.subcategory.name,
    })

    try {
      for (const file of data.attachment.media) {
        await addPdfWatermark(file.url, file.name, user)
      }
    } catch (error) {
      openSnackbar('Can not Download the file at this moment.')
    }

    setprocessingDownload(false)
  }

  const cardMediaRef = useRef(null);

  useEffect(() => {
    const data = []
    if (cardData) {
      if (cardData.edition_sections) {
        cardData.edition_sections.forEach((item) => {
          data.push({
            section: item.section,
            contents: [],
          })
        })

        if (cardData.contents) {
          cardData.contents.forEach((item) => {
            const sectionPos = data.findIndex(
              (section) => section.section.id === item.section.id
            )

            data[sectionPos].contents[item.position - 1] = {
              id: item.id,
              title: item.title,
              url: item.url,
              body: item.body,
            }
          })
        }
      }


    }

    setContent(data)

  }, [cardData])

  return (
    <div style={{ width: '100%' }}>
      {!cardData ? (
        <Skeleton variant="rect" animation="wave" width="100%" height={1200} />
      ) : (
        <Card variant="elevation">
          <CardContent>
            <Box className={classes.cardInfoBox}>
              <DownloadButton
                cardData={cardData}
                processingDownload={processingDownload}
                onClick={handleDownloadButtonClick}
                customStyles={{ marginLeft: 'auto' }}
              />
            </Box>

            <Box className={classes.cardInfoBox}>
              <Typography
                className={`${classes.cardInfoText} ${classes.subcategory}`}
                gutterBottom>
                {cardData.subcategory.name}
              </Typography>
              <Typography
                className={classes.cardInfoText}
                style={{ marginLeft: 'auto' }}
                gutterBottom>
                  { // display new date for "Here's where to find"
                    cardData.id === "266" 
                      ? moment(new Date()).format('Do MMM YYYY')
                      : moment(
                          cardData.date ? cardData.date : cardData.published_at
                        ).format('Do MMM YYYY')
                  }
              </Typography>
            </Box>

            <Typography className={classes.cardTitle} gutterBottom>
              {cardData.title}
            </Typography>

            <Box className={classes.cardInfoBox}>
              <Typography className={classes.cardInfoText} gutterBottom>
                Tags:
              </Typography>
              {cardData.tags.map((tag) => (
                <Link
                  key={tag.name}
                  className={classes.linkWrapper}
                  to={`/tags/${tag.name}`}>
                  <Chip
                    label={tag.name}
                    className={`${classes.badge} ${classes.cardInfoText}`}
                    size="small"
                    variant="outlined"
                    clickable
                  />
                </Link>
              ))}
            </Box>

            {cardData.subtitle ? (
              <Typography className={classes.cardInfoText} gutterBottom>
                {cardData.subtitle}
              </Typography>
            ) : (
              <div>
                {cardData.subcategory.description ? (
                  <div className={classes.cardInfoText} gutterBottom>
                    {parse(cardData.subcategory.description)}
                  </div>
                ) : null
                }
              </div>
            )}
            <Box className={classes.cardBody}>
              <CardMedia
                ref={cardMediaRef}
                component={cardData.url ? 'iframe' : 'img'}
                className={cardData.url ? classes.video : classes.image}
                alt={`Cover Image: ${cardData.title}`}
                title={`Cover Image: ${cardData.title}`}
                src={
                  cardData.url
                    ? `${getVideoEmbedUrl(cardData.url, cardMediaRef)}`
                    : cardData.cover_image
                      ? cardData.cover_image.url
                      : null
                }
                allowFullScreen='allowfullscreen'
              />
              <div className="ck-content">
                {content.map((section, index) => (
                  <div className={classes.content} key={index}>
                    <h2>{section.section.name}</h2>
                    {section.contents.map((item) => parse(item.body))}
                  </div>
                ))}
              </div>
            </Box>
            <Box className={classes.cardInfoBox}>
              <Typography className={classes.cardInfoText}>
                {category === 'Videos'
                  ? makeAuthorString(
                    cardData.author_editions
                      .filter((item) => item.main)
                      .map((item) => item.author),
                    null,
                    cardData.subcategory
                  )
                  : makeAuthorString(
                    cardData.author_editions
                      .filter((item) => item.main)
                      .map((item) => item.author),
                    cardData.author_editions
                      .filter((item) => !item.main)
                      .map((item) => item.author),
                    cardData.subcategory
                  )}
              </Typography>
            </Box>

            <DownloadButton
              cardData={cardData}
              processingDownload={processingDownload}
              onClick={handleDownloadButtonClick}
            />
          </CardContent>
        </Card>
      )}
    </div>
  )
}

const DownloadButton = ({ cardData, processingDownload, onClick, customStyles }) => {
  return (
    cardData.attachment &&
    cardData.attachment.media.length > 0 &&
    <div style={{
      display: "flex",
      alignItems: "center",
      ...customStyles
    }}>
      <Button
        variant="contained"
        endIcon={processingDownload ? <CircularProgress color="inherit" size={"1em"} /> : <GetAppIcon />}
        color="primary"
        disabled={
          (
            (cardData && cardData.attachment && cardData.attachment.media.length > 0)
            &&
            !processingDownload
          )
            ? false
            : true
        }
        onClick={() => onClick(cardData)}
      >
        Download the full report
      </Button>
    </div>
  )
}

export default ArticleCard